/* eslint-disable no-else-return */

import { app, db } from 'base';
// import { AuthContext } from './auth'

let singleUsersDatabase = null;

if (app.auth().currentUser) {
  singleUsersDatabase = db.collection('quiz')
    .doc(app.auth().currentUser.phoneNumber);
  // singleUsersDatabase
  //   .onSnapshot({ includeMetadataChanges: true }, (snapshot) => {


  //     if (!snapshot) return;
  //     // console.log('offline', snapshot._fromCache);

  //     // snapshot.docChanges().forEach((change) => {
  //     //   if (change.type === 'added') {
  //     //     console.log('New city: ', change.doc.data());
  //     //   }

  //     //   const source = snapshot.metadata.fromCache ? 'local cache' : 'server';
  //     //   console.log(`Data came from ${source}`);
  //     // });
  //   });
}

export const getMe = async () => {
  if (!app.auth().currentUser) return {};
  const snapshot = await db.collection('users').doc(app.auth().currentUser.phoneNumber.replace('+', '')).get();
  return snapshot.data();
};

// const resultsCollection = app.auth().currentUser ? singleUsersDatabase.collection('results') : null;
// const evaluationCollection = app.auth().currentUser ? singleUsersDatabase.collection('evaluation') : null;

// const resultsCollection = singleUsersDatabase ? singleUsersDatabase.collection('results') : null;

export const returnDBResults = () => {
  return singleUsersDatabase.collection('results')
    .get()
    .then((collectionResults) => {
      if (collectionResults.size) {
        const results = collectionResults.docs.map((doc) => {
          return { [doc.id]: doc.data() };
        });
        return results;
      }
      return null;
    })
    .catch((error) => {
      console.error("Coudn't fetch data from result collection", error);
    });
};


export const returnSingleDBResult = async (id) => {
  if (!singleUsersDatabase) {
    singleUsersDatabase = await db.collection('quiz').doc(app.auth().currentUser.phoneNumber);
  }
  return singleUsersDatabase
    .collection('results')
    .doc(id)
    .get()
    .then((doc) => {
      if (!doc.exists) {
        return { done: false, checkpoint: 0 };
      } else {
        return { done: doc.data().done && doc.data().done > 0, checkpoint: (doc.data().checkpoint) ? doc.data().checkpoint : 0 };
        // return doc.data().done && doc.data().done > 0;
      }
    })
    .catch((error) => {
      console.error(`Couldn´t get single db result from id ${id}`, error);
    });
};

export const saveQuizResult = async (id, value) => {
  const data = { done: value, checkpoint: 0 }; // The data we will write to the chosen ID in Results collection.
  const docRef = singleUsersDatabase.collection('results').doc(id); // Ref to chosen ID inside Results collection.
  const getDocRef = await docRef.get().catch(
    error => console.error('Error fetching result doc: ', error),
  ); // Get ref to check if it exists.

  const save = (!getDocRef || !getDocRef.exists)
    ? await docRef.set(data).then(
      () => { return true; },
    ).catch(
      (error) => {
        console.error('Error saving data result doc: ', error);
        return false;
      },
    )
    : await docRef.update(data).then(
      () => { return true; },
    ).catch(
      (error) => {
        console.error('Error updating data result doc: ', error);
        return false;
      },
    );

  return save;
};

export const saveQuizCheckpoint = async (id, value) => {
  console.log('store: ', id, value);
  const data = { checkpoint: value }; // The data we will write to the chosen ID in Results collection.
  const docRef = singleUsersDatabase.collection('results').doc(id); // Ref to chosen ID inside Results collection.
  const getDocRef = await docRef.get().catch(
    error => console.error('Error fetching result doc: ', error),
  ); // Get ref to check if it exists.

  const save = (!getDocRef || !getDocRef.exists)
    ? await docRef.set(data).then(
      () => { return true; },
    ).catch(
      (error) => {
        console.error('Error saving data result doc: ', error);
        return false;
      },
    )
    : await docRef.update(data).then(
      () => { return true; },
    ).catch(
      (error) => {
        console.error('Error updating data result doc: ', error);
        return false;
      },
    );

  return save;
};

export const saveEvaluation = async (id, value) => {
  // The data we will write to the chosen ID in Evaluation collection.
  const data = { approve: value };

  // Ref to chosen ID inside Evaluation collection.
  const docRef = singleUsersDatabase.collection('evaluation').doc(id);

  // Get ref to check if it exists.
  const getDocRef = await docRef.get();

  (!getDocRef.exists) ? await docRef.set(data) : await docRef.update(data);

};

// Use history obj via withRouter/react-router;
export const signOut = () => {
  app.auth().signOut();
};
