import React from 'react';
import LoadingAnimation from 'components/LoadingAnimation';

function Loading() {
  return (
    <div className="loading-animation loading-animation--transparent">
      {/* <svg height="100" width="100" className="spinner__wrap" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <circle
          className="spinner"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeDasharray="2, 4.89"
          cx="50"
          cy="50"
          r="48.33"
        />
      </svg> */}
      <LoadingAnimation />
    </div>
  );
}

export default Loading;
