import React from 'react';
import PropTypes from 'prop-types';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.error('error', error);
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.error('Error. Info: ', error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{ display: 'flex', width: '100%' }}
        >
          <div
            style={{
              margin: 'auto',
              padding: 20,
              background: 'white',
              color: 'black',
              position: 'relative',
              zIndex: 10,
              borderRadius: 8,
            }}
          >
            <h4 style={{ color: 'black' }}>
              Something went wrong
            </h4>
            <p>We&apos;re terribly sorry <span role="img" aria-label="faceplant">🤦‍♀️</span></p>
            <p>
              <a href="/" className="button" type>Go to start</a>
            </p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
