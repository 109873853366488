/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { getMe, returnSingleDBResult } from 'helpers/firebase';
import { isUserProspect, removeTrailingSlash } from 'helpers';
import { app } from 'base';
import { introductionQuiz } from './utils/settings';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState('loading');

  const userInfo = async (loggedIn) => {
    if (!loggedIn) {
      setCurrentUser(null);
    }else{
      const user = await getMe();
      const isProspect = isUserProspect(user);
      const introComplete = await returnSingleDBResult(introductionQuiz[0]);

      if (isProspect) {
        setCurrentUser({ ...user, introComplete, isProspect}); 
      } else {
        setCurrentUser({ user, introComplete: {done: true}, isProspect });
      }
    }
  };
    
  useEffect(() => {
    app.auth().onAuthStateChanged(userInfo);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,

      }}
    >
      {currentUser !== 'loading' && children}
    </AuthContext.Provider>
  );
};