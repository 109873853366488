/* eslint-disable */

import { handleOverlay } from '../state/actions';

export const filterQuestions = (questions, amount) => {
  const currentAmount = 0;
  return questions.filter(() => {
    if (currentAmount < amount) {
      currentAmount + 1;
      return true;
    }
    return false;
  });
};


export const handleRouteWithOverlay = ({
  dispatch, route, history, overlay,
}) => {
  dispatch(handleOverlay({ ...overlay, show: true }));
  setTimeout(() => {
    history.push(route);
  }, 500);
};

export const handleHideOverlay = ({ dispatch, hideTime }) => {
  setTimeout(() => {
    dispatch(handleOverlay({ show: false, variant: null, time: false }));
  }, hideTime);
};

export const shuffle = (a) => {
  const newValue = a;
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return newValue;
};

// Returns a reading time based on the letters in the string
export const countReadingTime = (title) => {
  const titleLength = title.length;

  const time = 350 // human reaction time /350)
    + (titleLength * 50); // x ms per letter

  return time;
};

export const findObjectByKey = (array, key, value) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === value) {
      return array[i];
    }
  }
  return null;
};


export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  // The maximum is exclusive and the minimum is inclusive
  return Math.floor(Math.random() * (max - min)) + min;
};

// Get random value from array
export const sample = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

// Calculates wich period the quiz is in and return 1 || 2 || 3 based on wich period it is.
// 1 = goalkeeper
// 2 = skates
// 3 = stick
export const getPeriod = () => {
  if (new Date() >= new Date('2019-08-05')) {
    return 3;
  } if (new Date() >= new Date('2019-06-28')) {
    return 2;
  }
  return 1;

};

export const getReggedTierKey = () => {
  // Set key based on period
  // If period 1: regged_tier (for backwards compatability)
  // Period 2: regged_tier_2,  Period 2: regged_tier_3,
  const period = getPeriod();
  switch (period) {
  case 3:
    return 'regged_tier_3';
  case 2:
    return 'regged_tier_2';
  case 1:
  default:
    return 'regged_tier';
  }
};

// Return date or time from string with structure 10/10 2019 15:21:27
export const getDateString = (date) => {
  const parts = date.split(' ');
  
  if (!parts[0] || !parts[1]) return '';

  const newDateStr = `${parts[0]} ${parts[1]}`;
  return newDateStr;
};

export const getTimeString = (date) => {
  const parts = date.split(' ');

  if (!parts[2]) return '';

  return parts[2].substr(0, 5);
};

export async function addImagesToCache(urls) {
  const myCache = await window.caches.open('images');
  await myCache.addAll(urls);
}

export function isUserProspect(user) {
  if (user && user.permissions && user.defaultVenue) {
    if (user.permissions[user.defaultVenue]
        && user.permissions[user.defaultVenue].role
        && user.permissions[user.defaultVenue].role !== 'prospect') {
      return false;
    }
  }

  return true;
}

export function slugify(string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export function removeTrailingSlash(string){
  return string.endsWith('/') ? string.substring(0, string.length - 1) : string;
}

