import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import BackgroundGradient from 'components/BackgroundGradient';
import Loading from 'components/Loading';
import SplashScreen from 'components/SplashScreen';
import { AuthProvider } from './auth';
import PrivateRoute from './PrivateRoute';

const NewAccount = lazy(() => import('views/NewAccount'));
const Quiz = lazy(() => import('views/Quiz'));
const Login = lazy(() => import('views/Login'));
const Dashboard = lazy(() => import('views/Dashboard'));
const News = lazy(() => import('views/News'));
const PrivateTest = lazy(() => import('views/PrivateTest'));
const QuizOverview = lazy(() => import('views/QuizOverview'));
const Evaluation = lazy(() => import('views/Evaluation'));
const Profile = lazy(() => import('views/Profile'));
const ViewVideo = lazy(() => import('views/ViewVideo'));
const Documents = lazy(() => import('views/Documents'));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  // constructor(props) {
  //   super(props);
  // this.db = firebase.firestore().enablePersistence()
  //   .then((data) => {
  //     console.log('db', this.db);
  //     console.log('datan', data);
  //   })
  //   .catch((err) => {
  //     if (err.code === 'failed-precondition') {
  //       // Multiple tabs open, persistence can only be enabled
  //       // in one tab at a a time.
  //       console.log('Please close other tabs of Pinchoversity');
  //     } else if (err.code === 'unimplemented') {
  //       // The current browser does not support all of the
  //       // features required to enable persistence
  //       // ...
  //       console.log('You\'re browser doesn\'t support offline usage');
  //     }
  //   });
  // }

  componentDidMount() {


    const loader = document.getElementById('loading-animation');

    document.addEventListener('DOMContentLoaded', () => {
      loader.style.opacity = 0;
      loader.style.pointerEvents = 'none';
      this.initialLoaderTimeout = setTimeout(() => {
        loader.remove();
        this.setState({ loading: false });
      }, 1200);
    });
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
    clearTimeout(this.initialLoaderTimeout);
  }

  render() {
    if (this.state.loading) return <SplashScreen />;

    return (
      <AuthProvider>
        {/* <BackgroundGradient /> */}
        <Suspense fallback={<Loading />}>
          <Router>
            <Switch>
              <Route
                exact
                path="/login"
                component={Login}
              />
              <PrivateRoute
                exact
                path="/"
                component={Dashboard}
                prospect
                // db={this.db}
              />
              <PrivateRoute
                path="/video/:id"
                component={ViewVideo}

                // db={this.db}
              />
              <PrivateRoute
                path="/news"
                component={News}
                // db={this.db}
              />
              <PrivateRoute
                exact
                path="/quiz"
                component={QuizOverview}
                prospect
                // db={this.db}
              />
              <PrivateRoute
                exact
                path="/profile"
                component={Profile}
                prospect
                // db={this.db}
              />
              <Route
                exact
                path="/new-account"
                component={NewAccount}
              />
              <PrivateRoute
                exact
                path="/evaluation/:id"
                component={Evaluation}
              />
              <PrivateRoute
                path="/quiz/:id"
                component={Quiz}
                prospect
                start
                // db={this.db}
              />
              <PrivateRoute
                path="/files"
                component={Documents}
                // db={this.db}
              />
              <PrivateRoute
                path="/files/:id"
                component={Documents}
                // db={this.db}
              />
              <PrivateRoute
                path="/private"
                component={PrivateTest}
                // db={this.db}
              />
              <PrivateRoute
                path="/quiz"
                test="tier"
                prospect
                component={QuizOverview}
                // db={this.db}
              />
            </Switch>
          </Router>
        </Suspense>
      </AuthProvider>
    );
  }
}

export default App;
