import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import { Howl } from 'howler';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import App from './App';
import * as serviceWorker from './serviceWorker';


const bugsnagClient = bugsnag('03a531497fdddc13a73779c0812b74e1');
bugsnagClient.use(bugsnagReact, React);

// wrap your entire app tree in the ErrorBoundary provided
const ErrorBoundary = bugsnagClient.getPlugin('react');

// usage: window.soundeffects.play('levelup');
window.soundeffects = new Howl({
  src: ['./sounds/soundeffects.mp3'],
  html5: true,
  format: ['mp3'],
  sprite: {
    klick: [0, 384],
    countdown: [1000, 2000],
    timesup: [3000, 2046],
    success: [6000, 1151],
    fail: [7151, 1031],
    goodwork: [8200, 6800],
    almost: [15000, 4330],
  },
});

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root'),
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
