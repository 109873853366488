import React from 'react';

import './index.scss';
import LottieAnimation from '../LottieAnimation';
import JugglingLottie from '../../assets/lottie/elefant_on_ball.json';

const LoadingAnimation = () => (
  <div className="loading-animation">
    <div className="loading-animation__wrapper">
      <LottieAnimation animationData={JugglingLottie} loop width="170px" height="200px" />
    </div>
  </div>
);

export default LoadingAnimation;
