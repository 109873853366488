const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/firestore');
require('firebase/functions');


/* Fully firebase */
/*

const firebaseConfig = {
  apiKey: 'AIzaSyBl0O3TmcbgLNdumcJWw1M28tq8d6vsJjI',
  authDomain: 'pinchos-quiz.firebaseapp.com',
  databaseURL: 'https://pinchos-quiz.firebaseio.com',
  projectId: 'pinchos-quiz',
  storageBucket: '',
  messagingSenderId: '1084484127460',
  appId: '1:1084484127460:web:86b1569a14bf0f92',
};

*/

const firebaseConfig = {
  apiKey: 'AIzaSyA0A9nzyg37yNOSe2CdAMB32rgm5m7BgNw',
  authDomain: 'blupp-43119.firebaseapp.com',
  databaseURL: 'https://blupp-43119.firebaseio.com',
  projectId: 'blupp-43119',
  storageBucket: 'blupp-43119.appspot.com',
  messagingSenderId: '522680239423',
  bucketId: 'pinchos-images',
};


// Initialize Firebase

export const app = firebase.initializeApp(firebaseConfig);

export const db = app.firestore();

export const verifyUser = firebase.functions().httpsCallable('verifyUser');
export const exists = firebase.functions().httpsCallable('exists');

// db.enablePersistence()
//   .catch((err) => {
//     if (err.code === 'failed-precondition') {
//       console.error('Please close all other tabs of Pincho VIP: ', err);
//       // Multiple tabs open, persistence can only be enabled
//       // in one tab at a a time.
//       // ...
//     } else if (err.code === 'unimplemented') {
//       console.error('Please use a never browser. ', err);

//       // The current browser does not support all of the
//       // features required to enable persistence
//       // ...
//     }
//   });

// eslint-disable-next-line eol-last
export default app;