/* eslint-disable */
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './auth';
import ErrorBoundary from 'components/ErrorBoundary';
import { introductionQuiz, prospectQuiz } from 'utils/settings'

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = useContext(AuthContext);
  const customProps = rest.customProps ? rest.customProps : {};
  console.log({rest});
  return (
    <Route
      {...rest}
      render={routeProps =>
        currentUser ? (
          (!currentUser.introComplete.done && routeProps.match.url !== `/quiz/${introductionQuiz}`) ?
            <Redirect
              to={{
                pathname: `/`,
                state: { from: routeProps.location.pathname }
              }}
            />
            :
            (currentUser.isProspect && routeProps.match.path === '/quiz/:id' && !prospectQuiz.includes(routeProps.match.params.id)) 
              ?
              <Redirect
                to={{
                  pathname: `/quiz/`,
                  state: { from: routeProps.location.pathname }
                }}
              />
              :
              (currentUser.isProspect && !rest.prospect)
                ?
                <Redirect
                  to={{
                    pathname: '/',
                    state: { from: routeProps.location.pathname }
                  }}
                />
                :
            <ErrorBoundary>
              <RouteComponent {...customProps} currentUser={currentUser} {...routeProps} />
            </ErrorBoundary>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: routeProps.location.pathname }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
