import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react-web';
import './index.scss';

const getDefaultOptions = (animationData, loop) => {
  return {
    loop,
    autoplay: true, 
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
};

const LottieAnimation = (props) => {
  const [animationData, setAnimationData] = useState(props.externalJSON ? null : props.animationData);
  const [loading, setLoading] = useState(true);
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    // Prevent bug where segments won't loop
    setTimeout(() => {
      setRerender(true);
    }, 100 + props.delay);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, props.delay);
  }, []);
  
  useEffect(() => {
    async function fetchExternalJSON() {
      try {
        const response = await fetch(
          props.externalJSON,
        );

        const json = await response.json();
        setAnimationData(json);
      } catch (error) {
        console.log(error);
      }
    }

    if (props.externalJSON) {
      console.log(props.externalJSON);
      fetchExternalJSON();
    }
  }, []);

  return (
    <div className={`lottiecontainer__${props.fill} lottiecontainer__${rerender ? 'rerendered' : 'waiting'}`}>
      {loading ? (
        null
      ) : (
        <Lottie 
          options={getDefaultOptions(animationData, props.loop)}
          width={props.width}
          height={props.height}
          segments={props.segments}
        />
      )
      }
    </div>
  );
};

LottieAnimation.propTypes = {
  animationData: PropTypes.object,
  loop: PropTypes.bool,
  externalJSON: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  segments: PropTypes.array,
  fill: PropTypes.string,
  delay: PropTypes.number,
};

LottieAnimation.defaultProps = {
  animationData: null,
  loop: true,
  externalJSON: null,
  width: '90%',
  height: 'auto',
  segments: null,
  fill: null,
  delay: 0,
};

export default LottieAnimation;
