import React from 'react';
import SplashImage from '../../assets/icons/splash-image.svg';

import './index.scss';

const SplashScreen = () => (
  <div className="splash-screen a-fadein">
    <img src={SplashImage} alt="splashimage" />
  </div>
);

export default SplashScreen;
